<template>
  <div class="page">
    <div>
    <div id="i_can_do">
      <div class='left'>
        <div>回归朴素的<span class="zi2">词频、</span><span class="zi4">分类、</span> <span class="zi3">排除法</span>背单词，把它们做透，</div>
        <div>重视 <span class="zi1">全局感</span> 和 <span class="zi5">事物间的相关性</span></div>
      </div>
      <div class='right'>
        <div class="app">
          <img src="https://www.wordvector.com/static/img/logo_en.png" alt="">
          <div>
            <span class="name">向量单词</span>
            <!-- <span>下载</span> -->
          </div>
        </div>
      </div>
    </div>
    </div>
    <div class="c">
      <div class="one_about">
        <p class="blue_title"
           data-aos="slide-up">从容</p>
        <div class="img">
          <img data-aos="zoom-up"
               src='https://www.wordvector.com/static/img/example/example_1.png'>
        </div>
      </div>
      <div class="one_about">
        <p class="blue_title"
           data-aos="slide-up">sea</p>
        <div class="img">
          <img data-aos="zoom-up"
               src='https://www.wordvector.com/static/img/example/example_2.png'>
        </div>
      </div>
      <div class="one_about">
        <p class="blue_title"
           data-aos="slide-up">坦诚</p>
         <div class="img">
          <img data-aos="zoom-up"
               src='https://www.wordvector.com/static/img/example/example_3.png'>
        </div>
      </div>
    </div>
    <div class="c">
      <div class="one_about">
        <p class="blue_title"
           data-aos="slide-up">coffee</p>
        <div class="img">
          <img class="shadow" data-aos="zoom-up"
               src='https://www.wordvector.com/static/img/example/example_4.png'>
        </div>
      </div>
      <div class="one_about">
        <p class="blue_title"
           data-aos="slide-up">平和</p>
        <div class="img">
          <img class="shadow" data-aos="zoom-up"
               src='https://www.wordvector.com/static/img/example/example_5.png'>
        </div>
      </div>
      <div class="one_about">
        <p class="blue_title"
           data-aos="slide-up">water</p>
        <div class="img">
          <img class="shadow" data-aos="zoom-up"
               src='https://www.wordvector.com/static/img/example/example_6.png'>
        </div>
      </div>
    </div>
    <div class="c">
      <div class="one_about">
        <p class="blue_title"
           data-aos="slide-up">坚忍</p>
        <div class="img">
          <img class="shadow"
               data-aos="zoom-up"
               src='https://www.wordvector.com/static/img/example/example_7.png'>
        </div>
      </div>
      <div class="one_about">
        <p class="blue_title"
           data-aos="slide-up">want</p>
        <div class="img">
          <img class="shadow"
               data-aos="zoom-up"
               src='https://www.wordvector.com/static/img/example/example_8.png'>
        </div>
      </div>
      <div class="one_about">
        <p class="blue_title"
           data-aos="slide-up">不卑不亢</p>
        <div class="img">
          <img class="shadow"
               data-aos="zoom-up"
               src='https://www.wordvector.com/static/img/example/example_9.png'>
        </div>
      </div>
    </div>
    <div id='to_login'
         data-aos="zoom-in">
      <span>去应用商店搜索下载试试看吧~</span>
    </div>
    <div id='floor'>
      <span>泰安道听途想网络科技有限公司</span>
      <span>微信:18766650002</span>
      <a target="_blank"
         href="https://www.wordvector.com/agreement"><span>用户协议</span></a>
      <a target="_blank"
         href="https://beian.miit.gov.cn/#/Integrated/index"><span>鲁ICP备20026180号-7</span></a>
      <div style="display:inline-block; vertical-align:middle;">
        <img style="width:0.15rem;high:0.15rem; margin-right:0.01rem;" src="@/assets/img/gongan.png"  alt="">
        <span>37092102000149</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Utils from '../../utils/utils'
export default {
  name: 'index_pc',
  data () {
    return {
    }
  },
  mounted () {
    Utils.is_ios()
    // this.get_index()
  },
  methods: {
    get_index () {
      axios.post('/index_unlogin/', { type: 'pc', sid: this.$route.query.sid, qid: this.$route.query.qid })
        .then(res => {
          // console.log(res)
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          console.log(222)
        })
    }
  }
}
</script>

<style scoped>
/* .page{
  background-color:  #212b36;
} */
#i_can_do{
  padding: 0.3rem 0.1rem 0.2rem 0.1rem;
  margin: 0rem 1rem  0.2rem 1rem;
  background: rgba(37, 130, 173, 0.12);
  font-size: 0.18rem;
  text-align: left;
}
#i_can_do .left,#i_can_do .right{
  display: inline-block;
  vertical-align: middle;
}
#i_can_do .left{
  width: 4.5rem;
  margin: 0rem 0rem  0.2rem 1rem;
  text-align: left;
}
#i_can_do .right{
  margin-top: 0.06rem;
  width: 2.0rem;
}
#i_can_do .right div{
  text-align: left;
}
.c {
  width: 8.4rem;
  /* height: 6rem; */
  margin: 0rem auto 0.3rem auto;
}
.one_about {
  display: inline-block;
  text-align: center;
  padding: 0rem 0.05rem;
  margin: 0rem 0.1rem;
  width: 2.5rem;
  /* background: orange; */
}
.blue_title {
  width: 2rem;
  line-height: 0.01rem;
  padding: 0.13rem 0rem;
  margin: 0.72rem auto 0.1rem auto;
  text-align: center;
  display: block;
  background: rgba(37, 130, 173, 0.12);
  border-radius: 2rem;
  color: #fefefe;
  font-size: 0.12rem;
}

.one_about .img {
  /* height: 6rem; */
  height: 1.666rem;
  margin: 0.3rem 0rem 0.2rem 0rem;
}
.one_about .img img {
  width: 2rem;
}
.shadow {
  box-shadow: 0.01rem 0.02rem 0.02rem rgba(37, 130, 173, 0.1);
}

/* #2B3953  #30415D */
.one_about .desc {
  margin: 0.1rem 0rem 0.2rem 0rem;
  /* text-align: left; */
}
.one_about .desc p {
  line-height: 0.24rem;
  color: #fefefe;
  font-size: 0.1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#to_login {
  /* position: fixed;
  bottom: 66px;
  right: 0.8rem; */
  width: 8.4rem;
  /* height: 1rem; */
  text-align: center;
  margin: 0.5rem auto;
  /* background: #fdfdfd; */
}

#to_login span {
  display: inline-block;
  padding: 28px 0px 27px 0px;
  line-height: 1px;
  width: 600px;
  background: rgba(37, 130, 173, 0.12);
  border-radius: 100px;
  color: #fefefe;
  font-size: 24px;
}
#floor {
  /* position: fixed;
  bottom: 10px; */
  /* right: 0.8rem; */
  background: #2b3953;
  height: 0.5rem;
  line-height: 0.5rem;
  margin: 0.5rem auto 0rem auto;
  padding: 0.2rem auto 0rem auto;
}
#floor span {
  /* float: right; */
  display: inline-block;
  margin: 0rem 0.1rem;
  color: rgba(255, 255, 255, 0.4);
  font-size: 0.13rem;
}
.app{
  display: inline-block;
  width: 1.0rem;
  margin-left: 0.5rem;
  text-align: center;
}
.app img{
  width: 1.0rem;
  height: 1.0rem;
  border-radius: 0.12rem;
}
.app .name{
  display: block;
  font-size: 0.15rem;
  width: 1.0rem;
  text-align: center;
}
</style>
